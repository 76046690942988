import React, { useState } from 'react';
import firebase from '../firebase';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  FormControl,
  TextareaAutosize,
  CssBaseline,
  Button,
  Input,
  TextField,
} from '@material-ui/core';

const addMessage = (name, email, message) => {
  const db = firebase.firestore();
  db.collection('contact_us').add({
    name,
    email,
    message,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

export default props => {
  const { data } = props;
  const siteTitle = data.site.siteMetadata.title;
  const resetValue = {
    name: '',
    email: '',
    message: '',
  };
  const initValue = {
    ...resetValue,
    submitted: false,
  };
  const [state, setState] = React.useState(initValue);
  const recaptchaRef = React.useRef(null);
  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.value });
  };

  const handleSubmit = args => {
    recaptchaRef.current.execute();
  };

  const onRecaptchaChange = args => {
    if (args) {
      addMessage(state.name, state.email, state.message);
      setState({ ...state, submitted: true });
    }
  };

  if (state.submitted) {
    return (
      <Layout location={props.location} title={siteTitle}>
        <CssBaseline />
        <article>
          <h3>You message is successfully submitted.</h3>
        </article>
      </Layout>
    );
  }

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="Contact us" />
      <CssBaseline />
      <article>
        <h3>Got a question or comment?</h3>
        <FormControl>
          <TextField
            title="your name"
            placeholder="your name"
            onChange={handleChange('name')}
          />
          <TextField
            title="your email"
            placeholder="email (required if you want to receive response)"
            onChange={handleChange('email')}
          />
          <br />
          <TextareaAutosize
            title="your message"
            placeholder="message (required)"
            maxLength={3000}
            rowsMax={10}
            rows={5}
            style={{ width: '400px' }}
            onChange={handleChange('message')}
          />
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LfmtrkUAAAAAGQL093L3ogFxcbVwOYIFrI7aTjf"
            onChange={onRecaptchaChange}
            size="invisible"
          />
          <Button onClick={handleSubmit} disabled={!state.message}>
            Submit
          </Button>
        </FormControl>
      </article>
    </Layout>
  );
};
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
